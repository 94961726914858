import React, {Component} from "react";
import {Col, Container, Row} from "reactstrap";

import { languages, languageText, serviceAreas, timezones} from "../config";
import DataOverview from "./DataOverview";
import {connect} from "react-redux";
import { getProfileInfo } from "../client/actions/apiActions";
import Loader from "./layout/Loader";
import ProfileLinks from "./ProfileLinks";

const tableName = "users";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: 'loading',
      firstName: '',
      lastName: '',
      email: '',

      fieldData: [
        {
          fieldName: "firstName",
          fieldType: "text",
          colSize: 3,
          required: true
        },
        {
          fieldName: "lastName",
          fieldType: "text",
          colSize: 3,
          required: true
        },
        {
          fieldName: "email",
          fieldType: "text",
          colSize: 3,
          required: true
        },
        {
          fieldName: "cellPhone",
          fieldType: "text",
          colSize: 3
        },
        {
          fieldName: "workPhone",
          fieldType: "text",
          colSize: 3
        },
        {
          fieldName: "languageCode",
          fieldType: "dropdown",
          dropdownData: languages,
          colSize: 3
        },
        {
          fieldName: "image",
          fieldType: "image",
          colSize: 1
        },
        {
          fieldName: "timezone",
          fieldType: "dropdown",
          dropdownData: timezones,
          colSize: 2
        },
        {
          fieldName: "addressRadius",
          fieldType: "dropdown",
          dropdownData: serviceAreas,
          colSize: 2,
          defaultValue: 25
        },
        {
          fieldName: "address",
          fieldType: "geoLocate",
          colSize: 1
        },
        {
          fieldName: "addressLatitude",
          fieldType: "hidden",
          colSize: 0
        },
        {
          fieldName: "addressLongitude",
          fieldType: "hidden",
          colSize: 0
        },
      ],
      fieldDataResults: []
    };
  }

  componentDidMount() {
    this.props.getProfileInfo();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props)
    {
      let fieldDataResults = this.props.data.userProfile;

      this.setState({
        viewMode: 'data',
        fieldDataResults
      });
    }
  }

  render() {
    const {fieldDataResults, viewMode, fieldData} = this.state;
    console.log("fieldDataResults", fieldDataResults)
    console.log("fieldData", fieldData)
    if (viewMode === 'loading') {
      return (<Loader />);
    } else {
      const { languageCode } = this.props.auth.user;
      return (<Container fluid className="p-0">
        <Row>
          <Col md="3" xl="2">
            <ProfileLinks linkActive="/profile" />
          </Col>
          <Col md="9" xl="10">

            <DataOverview
                singleEdit={true}
                tableName={tableName}
                fieldData={fieldData}
                fieldDataResults={fieldDataResults}
                languageTable={tableName + "Profile"}
                pageTitle={languageText[languageCode].components[tableName + "Profile"].pageTitle}
                listTitle={languageText[languageCode].components[tableName + "Profile"].listTitle}
                listSubTitle={languageText[languageCode].components[tableName + "Profile"].listSubTitle}
                addTitle={languageText[languageCode].components[tableName + "Profile"].addTitle}
                addSubTitle={languageText[languageCode].components[tableName + "Profile"].addSubTitle}
                editTitle={languageText[languageCode].components[tableName + "Profile"].editTitle}
                editSubTitle={languageText[languageCode].components[tableName + "Profile"].editSubTitle}
            />

          </Col>
        </Row>
      </Container>);
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, {getProfileInfo} )(Profile);