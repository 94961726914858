import React, {Component} from "react";
import DataOverview from "../components/DataOverview";
const tableName = "event";

class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: "loading",
            tableColumns : [
                {
                    dataField: "status",
                    displayName: "Status",
                    type: "bool",
                    sort: false,
                    filter: false
                },
                {
                    dataField: "event_name",
                    displayName: "Title",
                    sort: true,
                    text: "Title"
                },
                {
                    dataField: "event_date",
                    displayName: "Date",
                    sort: true,
                    filter: false
                },
                {
                    dataField: "event_url",
                    displayName: "URL",
                    sort: true,
                    text: "URL"
                },
                {
                    dataField: "event_image",
                    displayName: "Image",
                    type: "image",
                    sort: true,
                    filter: false
                },
                {
                    dataField: "tools",
                    displayName: "Tools",
                    sort: false,
                    filter: false
                }
            ],
            fieldData: [
                {
                    fieldName: "event_name",
                    fieldType: "text",
                    colSize: 2,
                },
                {
                    fieldName: "event_date",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "time",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "end_time",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "repeat_event",
                    fieldType: "toggle",
                    colSize: 3
                },
                {
                    fieldName: "repeat_until",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "event_details",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "event_url",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "event_image",
                    fieldType: "image",
                    colSize: 3
                },
                {
                    fieldName: "event_pdf",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "event_keywords",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "include_rsvp",
                    fieldType: "toggle",
                    colSize: 3
                },
                {
                    fieldName: "add_email_blast",
                    fieldType: "toggle",
                    colSize: 3
                },
                {
                    fieldName: "add_rsvp",
                    fieldType: "toggle",
                    colSize: 3
                },
                {
                    fieldName: "recieve_rsvp",
                    fieldType: "toggle",
                    colSize: 3
                },
                {
                    fieldName: "email_reminder",
                    fieldType: "toggle",
                    colSize: 3
                },
                {
                    fieldName: "monthly_calender",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "event_attend",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "recieve_email",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "lat",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "lng",
                    fieldType: "text",
                    colSize: 3
                }
            ],
            fieldDataDefault: {
                event_name: ''
            },
        }
    }
    render() {
        const { tableColumns, fieldData, fieldDataDefault } = this.state;
        return (<DataOverview
            tableName={tableName}
            tableColumns={tableColumns}
            fieldData={fieldData}
            fieldDataDefault={fieldDataDefault}
            settings={{
                hideAddButton: true
            }}
        />);
    }
}
export default Clients;
