import React, {Component} from "react";
import {timezones, agencyTypes, serviceAreas, languageText} from "../config";
import DataOverview from "../components/DataOverview";
import ClientsEdit from "../pages/ClientsEdit";

const tableName = "community_provider";

class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: "loading",
            searchColumns: [
                'comm_name'
            ],
            tableColumns : [
                {
                    dataField: "status",
                    type: "bool",
                    sort: false,
                    text: "Status",
                    filter: false
                },
                {
                    dataField: "businessType",
                    type: 'businessType',
                    sort: true,
                    text: "Business Type",
                    filter: false
                },
                {
                    dataField: "dateAdded",
                    name: "Date Added",
                    sort: true,
                    text: "Date Added",
                    filter: false
                },
                {
                    dataField: "comm_name",
                    sort: true,
                    text: "Company Name"
                },
                {
                    dataField: "address",
                    sort: true,
                    text: "Address"
                },
                {
                    dataField: "city",
                    sort: true,
                    text: "City"
                },
                {
                    dataField: "state",
                    sort: true,
                    text: "State"
                },
                {
                    dataField: "zip_code",
                    sort: true,
                    text: "Zip Code"
                },
                {
                    dataField: "tools",
                    sort: false,
                    filter: false
                }
            ],
            fieldData: [
                {
                    fieldName: "name",
                    fieldType: "text",
                    colSize: 4,
                    required: true,
                    requiredMessage: "Organization Name is required!"
                },
                {
                    fieldName: "status",
                    fieldType: "toggle",
                    colSize: 3
                },
                {
                    fieldName: "cellPhone",
                    fieldType: "phone",
                    colSize: 3
                },
                {
                    fieldName: "workPhone",
                    fieldType: "phone",
                    colSize: 3
                },
                {
                    fieldName: "faxPhone",
                    fieldType: "phone",
                    colSize: 3
                },
                {
                    fieldName: "email",
                    fieldType: "email",
                    colSize: 2
                },
                {
                    fieldName: "website",
                    fieldType: "text",
                    colSize: 2
                },
                {
                    fieldName: "photo",
                    fieldType: "file",
                    colSize: 1
                },
                {
                    fieldName: "notes",
                    fieldType: "textarea",
                    colSize: 1
                },
                {
                    fieldName: "timezones",
                    fieldType: "dropdown",
                    dropdownData: timezones,
                    colSize: 3
                },
                {
                    fieldName: "agencyType",
                    fieldType: "dropdown",
                    dropdownData: agencyTypes,
                    colSize: 3
                },
                {
                    fieldName: "addressRadius",
                    fieldType: "dropdown",
                    dropdownData: serviceAreas,
                    colSize: 3,
                    defaultValue: 25
                },
                {
                    fieldName: "address",
                    fieldType: "geoLocate",
                    colSize: 1
                },
                {
                    fieldName: "addressLatitude",
                    fieldType: "hidden",
                    colSize: 0
                },
                {
                    fieldName: "addressLongitude",
                    fieldType: "hidden",
                    colSize: 0
                },
            ],
            fieldDataDefault: {
                name: '',
                status: true,
                cellPhone: '',
                workPhone: '',
                faxPhone: '',
                email: '',
                website: '',
                notes: '',
                timezones: '',
                agencyType: '',
                serviceArea: '',
                address: '',
                addressLatitude: '',
                addressLongitude: ''
            },
        }
    }
    render() {
        const { tableColumns, fieldData, fieldDataDefault, searchColumns } = this.state;
        return (<DataOverview
            tableName={tableName}
            tableColumns={tableColumns}
            fieldData={fieldData}
            fieldDataDefault={fieldDataDefault}
            searchColumns={searchColumns}
            settings={{
                hideAddButton: true,
                hideDeleteButton: true,
                customEditComponent: true,
                customComponent: ClientsEdit
            }}
        />);
    }
}
export default Clients;
