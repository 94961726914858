import React, {Component} from "react";
import {timezones, agencyTypes, serviceAreas, languageText} from "../config";
import DataOverview from "../components/DataOverview";
import ClientsEdit from "../pages/ClientsEdit";

const tableName = "incentives";

class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: "loading",
            tableColumns : [
                {
                    dataField: "incentive_name",
                    displayName: "Title",
                    sort: true,
                    text: "Title"
                },
                {
                    dataField: "start_date",
                    displayName: "Date",
                    sort: true,
                    text: "Start Date"
                },
                {
                    dataField: "end_date",
                    displayName: "URL",
                    sort: true,
                    text: "End Date"
                },
                {
                    dataField: "image",
                    displayName: "Image",
                    type: "image",
                    sort: true,
                    filter: false
                },
                {
                    dataField: "tools",
                    displayName: "Tools",
                    sort: false,
                    filter: false
                }
            ],
            fieldData: [
                {
                    fieldName: "incentive_name",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "start_date",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "end_date",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "incentive_limited",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "how_many",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "coming_soon_email",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "value",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "autocalculate_value",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "send_to",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "send_email",
                    fieldType: "text",
                    colSize: 3
                },
                {
                    fieldName: "description",
                    fieldType: "textarea",
                    colSize: 1
                },
                {
                    fieldName: "legal_desclaimer",
                    fieldType: "textarea",
                    colSize: 1
                },
                {
                    fieldName: "image",
                    fieldType: "image",
                    colSize: 1
                },
                {
                    fieldName: "published",
                    fieldType: "toggle",
                    colSize: 2
                },
                {
                    fieldName: "is_paused",
                    fieldType: "toggle",
                    colSize: 2
                },
            ],
            fieldDataDefault: {
                incentive_name: ''
            },
        }
    }
    render() {
        const { tableColumns, fieldData, fieldDataDefault } = this.state;
        return (<DataOverview
            tableName={tableName}
            tableColumns={tableColumns}
            fieldData={fieldData}
            fieldDataDefault={fieldDataDefault}
            settings={{
                hideAddButton: true
            }}
        />);
    }
}
export default Clients;
