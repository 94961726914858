export const GET_LIST = 'GET_LIST';
export const GET_DATA = 'GET_DATA';

export const SAVE_CONFIG = 'SAVE_CONFIG';
export const GET_CONFIG = 'GET_CONFIG';

export const SAVE_LISTING_AMOUNT = 'SAVE_LISTING_AMOUNT';
export const GET_LISTING_AMOUNT = 'GET_LISTING_AMOUNT';

export const GET_CHAT_MESSAGES = 'GET_CHAT_MESSAGES';

export const GET_LOGIN_STATUS = 'GET_LOGIN_STATUS';

// MESSAGE
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const GET_ERRORS = 'GET_ERRORS';

// USER AUTH
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const PAGE_ERROR = 'PAGE_ERROR';

export const FORGOT_PASS_SUCCESS = 'FORGOT_PASS_SUCCESS';
export const SAVE_FORGOT_PASS = 'SAVE_FORGOT_PASS';
export const CHECK_VERIFICATION_CODE = 'CHECK_VERIFICATION_CODE';
export const SEND_INVITE = 'SEND_INVITE';

export const SAVE_EMAIL_ADDRESS = 'SAVE_EMAIL_ADDRESS';
export const SAVE_EMAIL_ADDRESS_ERROR = 'SAVE_EMAIL_ADDRESS_ERROR';


// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = "SIDEBAR_VISIBILITY_TOGGLE";
export const SIDEBAR_VISIBILITY_SHOW = "SIDEBAR_VISIBILITY_SHOW";
export const SIDEBAR_VISIBILITY_HIDE = "SIDEBAR_VISIBILITY_HIDE";
export const SIDEBAR_STICKY_TOGGLE = "SIDEBAR_STICKY_TOGGLE";
export const SIDEBAR_STICKY_ENABLE = "SIDEBAR_STICKY_ENABLE";
export const SIDEBAR_STICKY_DISABLE = "SIDEBAR_STICKY_DISABLE";

// Layout
export const LAYOUT_BOXED_TOGGLE = "LAYOUT_BOXED_TOGGLE";
export const LAYOUT_BOXED_ENABLE = "LAYOUT_BOXED_ENABLE";
export const LAYOUT_BOXED_DISABLE = "LAYOUT_BOXED_DISABLE";

// Theme
export const THEME_TOGGLE = "THEME_TOGGLE";

export const GET_CITY = 'GET_CITY';
export const GET_STATE = 'GET_STATE';