import React, {Fragment} from "react";
import InviteUser from "./InviteUser";

export const ModalWindow = ({
    modalData, closeModalWindow, modalFunctionController
}) => {
    let openModalWindow = false;
    if (typeof(modalData.showModalWindow) !== 'undefined') openModalWindow = modalData.showModalWindow;
    if (openModalWindow === false) return null;

    return (<div className="modal" role="dialog" style={openModalWindow === true ? {display:'block', border: "1px solid black"}: ''}>
        <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{modalData.title}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModalWindow}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {modalData.type === 'inviteUser' && <InviteUser
                        modalData={modalData}
                        closeModalWindow={closeModalWindow}
                        modalFunctionController={modalFunctionController}
                    />}
                </div>
            </div>
        </div>
    </div>);

}
export default (ModalWindow);
