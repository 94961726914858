import React, {Component} from "react";
import {Container } from "reactstrap";
import DataList  from "./DataList";
import DataEdit  from "./DataEdit";
import { getTable, getRecord, saveRecord, archiveRecord, deleteRecord, exportOverview } from "../client/actions/apiActions";
import {connect} from "react-redux";
import Loader from "./layout/Loader";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {toastr} from "react-redux-toastr";
import {ApiURL, languageText} from "../config";

class DataOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: "loading",
            pageTitle: '',
            showExportData: true,
            listTitle: '',
            addTitle: '',
            editTitle: '',
            listSubTitle: '',
            addSubTitle: '',
            editSubTitle: '',
            tableColumns : [],
            tableData: [],
            filters: [],
            fieldData: [],
            fieldDataResults: {},
            fieldDataDefault: {},
            fieldDataErrors: [],
            tableName: '',
            totalPages: 1,
            totalRecords: 0,
            searchText: '',
            searchColumns: [],
            changeValue: '',
            currentPage: 1,
            recordsPerPage: 25,
            sortDirection: 'ASC',
            sortFieldName: '',
            settings: {},
            organizationId: '',
            key: 0,

            timeOut: 5000,
            showCloseButton: true,
            progressBar: true,
            position: "top-right"
        }
    }
    componentDidMount() {
        let fieldDataResults = [];
        let settings = {};
        let singleEdit = this.props.singleEdit;
        if (singleEdit !== true) singleEdit = false;

        let showExportData = this.props.showExportData;
        if (showExportData !== false) showExportData = true;

        if (singleEdit === true) {
            fieldDataResults = this.props.fieldDataResults;
        }
        if (typeof(this.props.settings) !== 'undefined') {
            settings = this.props.settings;
        }

        let recordsPerPage = this.state.recordsPerPage;
        if (typeof(this.props.recordsPerPage) !== 'undefined') {
            recordsPerPage = this.props.recordsPerPage;
        }

        let organizationId = this.state.organizationId;
        if (typeof(this.props.organizationId) !== 'undefined') {
            organizationId = this.props.organizationId;
        }

        let sortFieldName = this.state.sortFieldName;
        if (typeof(this.props.sortFieldName) !== 'undefined') {
            sortFieldName = this.props.sortFieldName;
        }

        let sortDirection = this.state.sortDirection;
        if (typeof(this.props.sortDirection) !== 'undefined') {
            sortDirection = this.props.sortDirection;
        }

        let searchText = this.state.searchText;
        if (typeof(this.props.searchText) !== 'undefined') {
            searchText = this.props.searchText;
        }

        let searchColumns = this.state.searchColumns;
        if (typeof(this.props.searchColumns) !== 'undefined') {
            searchColumns = this.props.searchColumns;
        }
        this.setState({
            singleEdit: singleEdit,
            fieldDataResults: fieldDataResults,
            tableName: this.props.tableName,
            showExportData: showExportData,
            settings: settings,
            organizationId: organizationId,

            pageTitle: this.props.pageTitle,
            listTitle: this.props.listTitle,
            listSubTitle: this.props.listSubTitle,
            addTitle: this.props.addTitle,
            addSubTitle: this.props.addSubTitle,
            editTitle: this.props.editTitle,
            editSubTitle: this.props.editSubTitle,

            tableColumns : this.props.tableColumns,
            fieldData: this.props.fieldData,
            recordsPerPage: recordsPerPage,
            sortFieldName: sortFieldName,
            sortDirection: sortDirection,
            searchText: searchText,
            searchColumns: searchColumns
        });

        if (singleEdit === false) this.props.getTable(this.props.tableName, 1, recordsPerPage, sortFieldName, sortDirection, searchText, organizationId, searchColumns);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props)
        {
            if (this.props.data.dataType === "export" && this.props.data.status === 1 && this.props.data.code !== '' && typeof(this.props.data.code) !== 'undefined')
            {
                window.open(ApiURL + "/export/" + this.props.data.code);
            }
            else if (this.props.data.dataType === "addRecord")
            {
                this.setState({
                    fieldDataResults: this.props.data.dataValues,
                    pageMode: "add"
                });
            }

            else if (this.props.data.dataType === "getRecord")
            {
                if (this.props.settings && this.props.settings.customEditComponent) {
                    this.setState({
                        fieldDataResults: this.props.data.dataValues,
                        pageMode: "editCustom"
                    });
                } else {
                    this.setState({
                        fieldDataResults: this.props.data.dataValues,
                        pageMode: "edit"
                    });
                }


            }
            else if (this.props.data.dataType === "deleteRecord")
            {
                this.refreshFunction();
            }
            else if (this.props.data.dataType === "saveRecord")
            {
                if (this.props.data.status === 1) {
                    this.showToastr("success", "Save Successful", "Record was successfully saved.");
                } else {
                    this.showToastr("danger", "Error Saving", this.props.data.message);
                }
                if (this.state.singleEdit !== true) {
                    this.refreshFunction();
                } else {
                    this.props.getRecord(this.state.tableName, this.props.data.recordId);
                }
            }
            else if (this.props.data.dataType === "archiveRecord")
            {
                this.refreshFunction();
            }
            else if (this.props.data.dataType === "getTable")
            {
                this.setState({
                    tableData: this.props.data.dataValues,
                    currentPage: this.props.data.currentPage,
                    totalPages: this.props.data.totalPages,
                    totalRecords: this.props.data.totalRecords,
                    pageMode: "list"
                });
            }
        }
    }
    refreshFunction = (currentPage, recordsPerPage, sortFieldName, sortDirection, searchText, searchColumns, filters) => {
        this.setState({ pageMode: "loading" });
        if (typeof(currentPage) === 'undefined') currentPage = this.state.currentPage;
        if (typeof(recordsPerPage) === 'undefined') recordsPerPage = this.state.recordsPerPage;
        if (typeof(sortFieldName) === 'undefined') sortFieldName = this.state.sortFieldName;
        if (typeof(sortDirection) === 'undefined') sortDirection = this.state.sortDirection;
        if (typeof(searchText) === 'undefined') searchText = this.state.searchText;
        if (typeof(searchColumns) === 'undefined') searchColumns = this.state.searchColumns;
        if (typeof(filters) === 'undefined') filters = this.state.filters;

        let organizationId = this.state.organizationId;
        if (typeof(this.props.organizationId) !== 'undefined') {
            organizationId = this.props.organizationId;
        }
        if (typeof(organizationId) === 'undefined') {
            organizationId = '';
        }
        this.props.getTable(this.state.tableName, currentPage, recordsPerPage, sortFieldName, sortDirection, searchText, organizationId, searchColumns, filters);
    };
    addFunction = () => {
        this.setState({ pageMode: "loading" });
        this.props.getRecord(this.state.tableName, '0');
    }
    editFunction = (id) => {
        this.setState({ pageMode: "loading" });
        this.props.getRecord(this.state.tableName, id);
    }

    deleteFunction = (id) => {
        this.props.deleteRecord(this.state.tableName, id);
    }
    archiveRecord = (id) => {
        this.props.archiveRecord(this.state.tableName, id);
    }
    updatePage = (currentPage) => {
        this.setState({currentPage: currentPage});
        this.refreshFunction(currentPage, this.state.recordsPerPage);
    };
    deleteConfirmFunction = (id) => {
        confirmAlert({
            title: 'Confirm Deletion',
            message: 'Are you sure you wish to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.deleteFunction(id)
                },
                {
                    label: 'No'
                }
            ]
        });
    };
    saveFunction = (id, data) => {
        let prevPageMode = this.state.pageMode;
        this.setState({ pageMode: "loading"});
        let errors = 0;
        let languageCode = '';
        if (typeof(this.props.auth.user.languageCode) !== 'undefined') {
            languageCode = this.props.auth.user.languageCode;
        }
        if (typeof(this.props.auth.user.user) !== 'undefined') {
            if (typeof(this.props.auth.user.user.languageCode) !== 'undefined') {
                languageCode = this.props.auth.user.languageCode;
            }
        }

        let fieldDataErrors = [];
        const { fieldData, tableName } = this.state;
        let languageTable = this.props.languageTable;
        if (typeof(languageTable) === 'undefined') {
            languageTable = tableName;
        }
        if (this.props.organizationId !== '' && typeof(this.props.organizationId) !== 'undefined' && this.state.tableName === 'users')
        {
            data["organizationId"] = this.props.organizationId;
        }
        fieldData.map((field, index) => {
            if (field.required === true && (data[field.fieldName] === '' || typeof(data[field.fieldName]) === 'undefined')) {
                errors=1;
                fieldDataErrors[field.fieldName] = languageText[languageCode].components[languageTable].fields[field.fieldName].requiredMessage;
                this.showToastr("error", languageText[languageCode].components[languageTable].fields[field.fieldName].requiredMessage, '');
            }
            return null;
        });
        if (errors === 1) {
            this.setState({fieldDataErrors: fieldDataErrors,fieldDataResults: data, pageMode: prevPageMode, key: Math.floor(Math.random() * 101)});
        } else {
            data.created_by = this.props.auth.user.user_id;
            if(typeof(data.cities) !== 'undefined') data.cities = data.cities.replace(",all_city", "");
            
            this.props.saveRecord(this.state.tableName, id, data);
            this.setState({fieldDataErrors: []});
        }
    }
    exportFunction = () => {
        this.props.exportOverview(this.state.tableName);
    }
    changeValue = (name, e) => {
        let value = e.target.value;
        this.setState({[name]: value});
    };
    resetSearch = () => {
        this.setState({searchText: "", pageMode: "loading"});
        let searchText = "";
        let currentPage = this.state.currentPage;
        let recordsPerPage = this.state.recordsPerPage;
        let sortFieldName = this.state.sortFieldName;
        let sortDirection = this.state.sortDirection;
        this.refreshFunction(currentPage, recordsPerPage, sortFieldName, sortDirection, searchText);
    };
    searchOverview = () => {
        this.setState({ pageMode: "loading" });
        this.refreshFunction();
    };
    searchKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.searchOverview();
        }
    };
    closeFunction = () => {
        this.setState({ pageMode: "loading", fieldDataErrors: [] });
        this.refreshFunction();
    }
    onViewsUsers = (organizationId) => {
        window.location.href = "/organizations/users/" + organizationId;
    }
    changeRecordsPerPage = (e) => {
        this.setState({[e.target.name]: e.target.value, currentPage: 1});
        this.refreshFunction(1, e.target.value)
    };
    changeSort = (sortFieldName, sortDirection) => {
        if (sortDirection === "DESC") sortDirection = "ASC";
        else sortDirection = "DESC";
        this.setState({ pageMode: "loading", sortFieldName, sortDirection });

        this.refreshFunction(this.state.currentPage, this.state.recordsPerPage, sortFieldName, sortDirection);
    };
    showToastr = (type, title, message) => {
        const options = {
            timeOut: parseInt(this.state.timeOut),
            showCloseButton: this.state.showCloseButton,
            progressBar: this.state.progressBar,
            position: this.state.position
        };
        const toastrInstance =
            type === "info"
                ? toastr.info
                : type === "warning"
                ? toastr.warning
                : type === "error"
                    ? toastr.error
                    : toastr.success;

        toastrInstance(
            title,
            message,
            options
        );
    }

    toggleFilters = () => {
        this.setState({showFilters: !this.state.showFilters});
    }

    updateFilter = (newFilter) => {
        this.setState({filters: newFilter});
        let currentPage = this.state.currentPage;
        let recordsPerPage = this.state.recordsPerPage;
        let sortFieldName = this.state.sortFieldName;
        let sortDirection = this.state.sortDirection;
        let searchText = this.state.searchText;
        let searchColumns = this.state.searchColumns;
        this.refreshFunction(currentPage, recordsPerPage, sortFieldName, sortDirection, searchText, searchColumns, newFilter);
    }

    render() {
        let pageMode = this.state.pageMode;
        const {
            tableColumns, tableData, fieldData, fieldDataResults, sortFieldName, sortDirection,
            fieldDataErrors, singleEdit, currentPage, totalPages, totalRecords,
             recordsPerPage, searchText, settings, organizationId,
            showFilters, filters, filterText
        } = this.state;

        let { tableName, languageTable, overviewSettings } = this.props;
        let pageTitle;
        let languageCode;
        let languageOutput;
        if (this.props.auth) {
            if (typeof(languageTable) === 'undefined' || languageTable === '') {
                languageTable = tableName;
            }
            if (this.props.auth.user.languageCode) {
                languageCode = this.props.auth.user.languageCode;
            } else if (this.props.auth.user.user.languageCode) {
                languageCode = this.props.auth.user.user.languageCode;
            }
            languageOutput = languageText[languageCode].components[languageTable];
            pageTitle = languageOutput.pageTitle;
        }

        if (singleEdit === true) pageMode="edit";

        return (<Container fluid className="p-0">
            <h1 className="h3 mb-3">{pageTitle}</h1>
            {pageMode === "loading" && <Loader />}
            {pageMode === "list" && <DataList
                columns={tableColumns}
                tableData={tableData}
                tableName={tableName}
                languageTable={languageTable}
                sortFieldName={sortFieldName}
                sortDirection={sortDirection}
                searchText={searchText}
                settings={settings}
                currentPage={currentPage}
                totalPages={totalPages}
                totalRecords={totalRecords}
                recordsPerPage={recordsPerPage}
                organizationId={organizationId}

                showFilters={showFilters}
                filters={filters}
                filterText={filterText}
                updateFilter={this.updateFilter}
                showToastr={this.showToastr}
                toggleFilters={this.toggleFilters}

                resetSearch={this.resetSearch}
                onViewsUsers={this.onViewsUsers}
                changeValue={this.changeValue}
                searchOverview={this.searchOverview}
                searchKeyPress={this.searchKeyPress}
                onUpdatePage={this.updatePage}
                onAdd={this.addFunction}
                onEdit={this.editFunction}
                onCustomEdit={this.customEditFunction}
                onExport={this.exportFunction}
                onChangeRecordsPerPage={this.changeRecordsPerPage}
                changeSort={this.changeSort}
                onDelete={this.deleteConfirmFunction}
                onArchive={this.archiveRecord}
                key={this.state.key}
            />}

            {(pageMode === "add" || pageMode === "view" || pageMode === "edit") && <DataEdit
                mode={pageMode}
                singleEdit={singleEdit}
                title={languageOutput[pageMode + "Title"]}
                slogan={languageOutput[pageMode + "SubTitle"]}
                tableName={tableName}
                languageTable={languageTable}
                organizationId={organizationId}
                settings={settings}
                dataFields={fieldData}
                dataResults={fieldDataResults}
                dataErrors={fieldDataErrors}
                key={this.state.key}
                onArchive={this.archiveRecord}
                onSave={this.saveFunction}
                onCancel={this.closeFunction}
            />}

            {pageMode === "editCustom" && (
                React.createElement(
                    settings.customComponent,
                    {
                        tableName: tableName,
                        languageTable: languageTable,
                        fieldDataResults: fieldDataResults,
                        closeFunction: this.closeFunction,
                        showToastr: this.showToastr
                    }
                )
            )}
        </Container>);
    }
}
const mapStateToProps = state => ({
    auth: state.auth.user,
    data: state.pages.data,
});
export default connect(mapStateToProps, {getTable, getRecord, saveRecord, archiveRecord, deleteRecord, exportOverview} )(DataOverview);
