import {GET_LIST, GET_CITY, GET_STATE, GET_DATA, GET_CHAT_MESSAGES, GET_LISTING_AMOUNT, SAVE_LISTING_AMOUNT, GET_CONFIG} from "../actions/types";

const initialState = {
    data: {},
    dataList: {},
    cities: [],
    states: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_DATA:
        case GET_CONFIG:
            return {
                ...state,
                data: action.payload
            };

        case GET_LIST:
            return {
                ...state,
                dataList: action.payload
            };


        case GET_CHAT_MESSAGES:
            return {
                ...state,
                data: action.payload
            };
        case GET_CITY:
                return {
                    ...state,
                    cities: action.payload
                };
        case GET_STATE:
            return {
                ...state,
                states: action.payload
            };
        case GET_LISTING_AMOUNT:
            return {
                ...state,
                data: action.payload
            };
        case SAVE_LISTING_AMOUNT:
            return {
                ...state,
                data: action.payload
            };
        default:
            return state;
    }
}
