import React, { Component } from "react";
import { connect } from "react-redux";
import { toggleSidebar, hideSidebar, toggleSidebarNew } from "../../client/actions/sidebarActions";
import { languageText, WebsiteURL } from "../../config";
import { Link } from "react-router-dom";
import { getNotifications } from "../../client/actions/apiActions";
import { Collapse, Navbar, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Settings, User, Power as LogoutIcon, HelpCircle as HelpIcon } from "react-feather";

class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      defaultLanguage: 'en'
    };
    this.intervalHandle = null;
  }

  windowLinkClick = (linkTo) => {
    console.log(WebsiteURL + linkTo)
    window.location.href = linkTo;
  }


  render() {
    let userInfo = {};
    let selectedLang = "en";

    if (typeof(this.props.auth) !== 'undefined' && this.props.auth !== null) {
      if (typeof(this.props.auth.user) !== 'undefined' && this.props.auth.user !== null) {
        userInfo = this.props.auth.user.user;
        selectedLang = userInfo.languageCode;
      }
    }
    let avatar = '';
    let showSuperAdminPasswordMenu = (typeof(userInfo.userRole) != 'undefined' && userInfo.userRole == 1)?true:false;
    if (typeof(userInfo.image) === 'undefined' || userInfo.image === '') avatar = "/assets/img/avatars/default.jpg";

    return (<Navbar color="white" light expand>
        <span
            className="sidebar-toggle d-flex mr-2"
            onClick={() => toggleSidebarNew()}
        >
          <i className="hamburger align-self-center" />
        </span>

          <Collapse navbar>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <span className="d-inline-block d-sm-none">
                  <DropdownToggle nav caret>
                    <Settings size={18} className="align-middle" />
                  </DropdownToggle>
                </span>
                <span className="d-none d-sm-inline-block">
                  <DropdownToggle nav caret>
                    {avatar && <img
                        src={avatar}
                        className="avatar img-fluid rounded-circle mr-1"
                        alt={`${userInfo.firstName} ${userInfo.lastName}`}
                    />}
                    <span className="text-dark">{userInfo.firstName} {userInfo.lastName}</span>
                  </DropdownToggle>
                </span>
                <DropdownMenu right>
                  <DropdownItem>
                    <Link to="/profile">
                      <User size={18} className="align-middle mr-2" />
                      {languageText[selectedLang].navBar.profile}
                    </Link>
                  </DropdownItem>

                  { showSuperAdminPasswordMenu && 
                  <DropdownItem>
                    <Link to="/super-admin-password">
                      <Settings size={18} className="align-middle mr-2" />
                      {languageText[selectedLang].navBar.superAdminPassword}
                    </Link>
                  </DropdownItem>
                  }

                  <DropdownItem divider />

                  <DropdownItem>
                    <Link to="/help-center">
                      <HelpIcon size={18} className="align-middle mr-2" />
                      {languageText[selectedLang].navBar.help}
                    </Link>
                  </DropdownItem>

                  <DropdownItem>
                    <Link to="/auth/sign-out">
                      <LogoutIcon size={18} className="align-middle mr-2" />
                      {languageText[selectedLang].navBar.signOut}
                    </Link>
                  </DropdownItem>

                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>);
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getNotifications: () => dispatch(getNotifications()),
    toggleSidebar: () => dispatch(toggleSidebar()),
    hideSidebar: () => dispatch(hideSidebar()),
    toggleSidebarNew: () => dispatch(toggleSidebarNew()),
    dispatch
  }
}

export default connect(store => ({
  sidebar: store.sidebar,
  app: store.app,
  auth: store.auth,
  data: store.pages.data,
}), mapDispatchToProps ) (NavbarComponent);