import React, {Component} from "react";
import DataOverview from "../components/DataOverview";
import {languageText} from "../config"; // Import css

const tableName = "emailTemplates";
class EmailTemplates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: "loading",
            tableColumns : [
                {
                    dataField: "name",
                    text: "Template Name",
                    sort: true
                },
                {
                    dataField: "emailSubject",
                    text: "Email Subject",
                    sort: true
                },
                {
                    dataField: "emailFromName",
                    text: "From Name",
                    sort: true
                },
                {
                    dataField: "emailFromEmail",
                    text: "From Email",
                    sort: true
                },
                {
                    dataField: "tools",
                    text: "Tools",
                    sort: false,
                }
            ],
            fieldData: [
                {
                    fieldName: "name",
                    displayName: "Email Template Name",
                    placeholder: "email template name",
                    fieldType: "text",
                    colSize: 2,
                    required: true,
                    requiredMessage: "Email Template Name is required!"
                },
                {
                    fieldName: "emailSubject",
                    displayName: "Email Subject",
                    placeholder: "",
                    fieldType: "text",
                    colSize: 2
                },
                {
                    fieldName: "emailFromName",
                    displayName: "From Name",
                    placeholder: "",
                    fieldType: "text",
                    colSize: 2
                },
                {
                    fieldName: "emailFromEmail",
                    displayName: "From Email",
                    placeholder: "",
                    fieldType: "email",
                    colSize: 2
                },
                {
                    fieldName: "emailMessage",
                    displayName: "Email Message",
                    placeholder: "",
                    fieldType: "editor",
                    colSize: 1
                },
            ],
            fieldDataDefault: {
                name: '',
                emailFromName: '',
                emailFromEmail: '',
                emailSubject: '',
                emailMessage: '',
            },
        }
    }
    render() {
        const { tableColumns, fieldData, fieldDataDefault } = this.state;
        const { languageCode } = this.props.auth.user.user;
        return (<DataOverview
            tableName={tableName}
            sortFieldName="name"
            sortDirection="ASC"
            tableColumns={tableColumns}
            fieldData={fieldData}
            fieldDataDefault={fieldDataDefault}
        />);
    }
}
export default EmailTemplates;
