import React, {Component} from "react";
import {connect} from "react-redux";
import {getConfigSettings, getList, saveEmailConfig} from "../client/actions/apiActions";
import {toastr} from "react-redux-toastr";
import FormInput from "../components/form/FormInput";

class EmailConfig extends Component {
	constructor(props) {
		super(props);
		this.state = {
			smtpEmailHost: '',
			smtpFromEmail: '',
			smtpFromName: '',
			smtpFromPassword: '',
			supportNameFrom: '',
			supportEmailFrom: '',
			testTemplate: '',

			emailTemplates: {},
			errorMessages: {},

			timeOut: 5000,
			showCloseButton: true,
			progressBar: true,
			position: "top-right"
		}
	}

	showToastr = (type, title, message) => {
		const options = {
			timeOut: parseInt(this.state.timeOut),
			showCloseButton: this.state.showCloseButton,
			progressBar: this.state.progressBar,
			position: this.state.position
		};
		const toastrInstance =
			type === "info"
				? toastr.info
				: type === "warning"
					? toastr.warning
					: type === "error"
						? toastr.error
						: toastr.success;

		toastrInstance(
			title,
			message,
			options
		);
	}

	componentDidMount() {
		this.props.getConfigSettings();
		this.props.getList('emailTemplates');
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps !== this.props) {
			const data = this.props.data;
			if (data.emailTemplates && data.emailTemplates !== this.state.emailTemplates) {
				this.setState({
					emailTemplates: data.emailTemplates
				});
			}
			if (this.props.data.pageName === 'getListingAmount') {
				this.setState({
					smtpEmailHost: data.smtpEmailHost,
					smtpFromEmail: data.smtpFromEmail,
					smtpFromName: data.smtpFromName,
					smtpFromPassword: data.smtpFromPassword,
					supportNameFrom: data.supportNameFrom,
					supportEmailFrom: data.supportEmailFrom,
					testTemplate: data.testTemplate,
				});
			}
			if (data.success === true) {
				this.showToastr("success", "Saved Successfully", data.message);
			}
			if (data.success === false) {
				this.showToastr("error", "Error", data.message);
			}
		}
	}

	onChange = (type, entry, field) => {
		this.setState({
			[entry.target.name]: entry.target.value
		})

		let isValidForm = false;
		const name = entry.target.name;
		const value = entry.target.value;
		let errorMessages = this.state.errorMessages;

		if (value === "") {
			errorMessages[name] = 'This field is required.';
		}
		this.setState({errorMessages});
	}
	saveForm = () => {
		const {smtpEmailHost, smtpFromEmail, smtpFromName, smtpFromPassword, supportNameFrom, supportEmailFrom, testTemplate} = this.state;
		const errorMessages = {};

		this.setState({errorMessages});

		if (smtpEmailHost === "") {
			errorMessages.smtpEmailHost = 'This field is required.';
		} else if (smtpFromEmail === "") {
			errorMessages.smtpFromEmail = 'This field is required.';
		} else if (smtpFromEmail === "") {
			errorMessages.smtpFromName = 'This field is required.';
		} else if (smtpFromEmail === "") {
			errorMessages.smtpFromPassword = 'This field is required.';
		} else if (smtpFromEmail === "") {
			errorMessages.supportNameFrom = 'This field is required.';
		} else if (smtpFromEmail === "") {
			errorMessages.supportEmailFrom = 'This field is required.';
		} else {
			this.props.saveEmailConfig(smtpEmailHost, smtpFromEmail, smtpFromName, smtpFromPassword, supportNameFrom, supportEmailFrom, testTemplate);
		}
		this.setState({errorMessages});
	}
	isValidForm = () => {
		let errorMessages = this.state.errorMessages;
		let isValidForm = true;
		for (const key in errorMessages) {
			if (errorMessages[key] !== '') {
				isValidForm = false
			}
		}
		return isValidForm;
	}
	testSettings = () => {
		fetch('https://cp.seniorplicity.com/api/test-mail')
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					this.showToastr("success", "Saved Successfully", data.message);
				}
				if (!data.success) {
					this.showToastr("error", "Error", data.message);
				}
			});
	}

	render() {
		const {errorMessages, smtpEmailHost, smtpFromEmail, smtpFromName, smtpFromPassword, testTemplate, supportNameFrom, supportEmailFrom, emailTemplates} = this.state;
		return (
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h2>Email Config</h2>
						<div className="card">
							<div className="card-body">
								<form noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
									<div className="container">
										<div className="row">
											<div className="col-12">
												<h2>Support Email</h2>
											</div>
											<FormInput
												type="input"
												id="supportNameFrom"
												name="supportNameFrom"
												label="Support Name"
												placeholder="Support Name"
												value={supportNameFrom}
												colSize={2}
												onChange={this.onChange}
												error={errorMessages['supportNameFrom']}
											/>
											<FormInput
												type="input"
												id="supportEmailFrom"
												name="supportEmailFrom"
												label="Support Email"
												placeholder="Support Email"
												value={supportEmailFrom}
												colSize={2}
												onChange={this.onChange}
												error={errorMessages['supportEmailFrom']}
											/>
											<div className="col-12">
												<h2>Sending Email SMTP Settings</h2>
											</div>
											<FormInput
												type="input"
												id="smtpEmailHost"
												name="smtpEmailHost"
												label="SMTP Email Host Name"
												placeholder="SMTP Email Host Name"
												value={smtpEmailHost}
												colSize={2}
												onChange={this.onChange}
												error={errorMessages['smtpEmailHost']}
											/>
											<FormInput
												type="input"
												id="smtpFromName"
												name="smtpFromName"
												label="SMTP Email Name"
												placeholder="SMTP Email Name"
												value={smtpFromName}
												colSize={2}
												onChange={this.onChange}
												error={errorMessages['smtpFromName']}
											/>
											<FormInput
												type="input"
												id="smtpFromEmail"
												name="smtpFromEmail"
												label="SMTP Email Username"
												placeholder="SMTP Email Username"
												value={smtpFromEmail}
												colSize={2}
												onChange={this.onChange}
												error={errorMessages['smtpFromEmail']}
											/>
											<FormInput
												type="input"
												id="smtpFromPassword"
												name="smtpFromPassword"
												label="SMTP Email Password"
												placeholder="SMTP Email Password"
												value={smtpFromPassword}
												colSize={2}
												onChange={this.onChange}
												error={errorMessages['smtpFromPassword']}
											/>
											<FormInput
												type="dropdown"
												id="testTemplate"
												name="testTemplate"
												label="Test Template"
												placeholder="Test Template"
												dropdown={emailTemplates}
												value={testTemplate}
												colSize={2}
												onChange={this.onChange}
												error={errorMessages['testTemplate']}
											/>
										</div>
									</div>
									<button className="btn btn-primary" disabled={!this.isValidForm()} onClick={this.saveForm}>Update</button>
									<button className="btn btn-warning mx-3" onClick={this.testSettings}>Test Email Settings</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth.user,
	userPermissions: state.auth.user.userRole,
	data: state.pages.data,
});

export default connect(mapStateToProps, {getConfigSettings, saveEmailConfig, getList})(EmailConfig);
