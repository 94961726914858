import React, { Component } from 'react';
import classnames from "classnames";
import FormInputFancyCheckboxElement from "./FormInputFancyCheckboxElement";

class FormInputCheckbox extends Component {
    render () {
        const { name, value, label, colSize, helpText, checkboxData, selectedCheckboxes, handleCheckboxChange, error } = this.props;
        let divColSize;
        let colLength1;
        let colLength2;

        if (colSize === 0) { divColSize = 0; colLength1 = "col-sm-4"; colLength2 = "col-sm-8"; }
        if (colSize === 1) { divColSize = 12; colLength1 = "col-sm-12"; colLength2 = "col-sm-12"; }
        if (colSize === 2) { divColSize = 6; colLength1 = "col-sm-4"; colLength2 = "col-sm-8"; }
        if (colSize === 3) { divColSize = 4; colLength1 = "col-sm-4"; colLength2 = "col-sm-8"; }
        if (colSize === 4) { divColSize = 8; colLength1 = "col-sm-4"; colLength2 = "col-sm-8"; }

        return (
            <div className={`col-${divColSize}`}>
                <div className="container p-0">
                    <div className="form-group row border-bottom pb-2">
                        <label htmlFor={name} className={colLength1}>{label}</label>
                        <div className={colLength2}>
                            {checkboxData ? (checkboxData.map((options, index) => {
                                return (<div key={index} className="form-check form-check-inline">
                                    <label className="form-check-label text-left btn btn-outline-primary mb-2" htmlFor={name + index}>
                                        <FormInputFancyCheckboxElement
                                            className={classnames('form-check-input', { 'is-invalid': error})}
                                            type="checkbox"
                                            checked={value.indexOf("," + options.id.toString() + ",") !== -1 ? 'checked' : null }
                                            name={name}
                                            id={name + index}
                                            value={options.id}
                                            selectedCheckboxes={selectedCheckboxes}
                                            handleCheckboxChange={handleCheckboxChange}
                                        />
                                        {options.name}
                                    </label>
                                </div>);
                            })) : null}
                        </div>
                        {helpText && <small id={name+"Help"} className="col-12 form-text text-muted">{helpText}</small>}
                        {error && <div className="col-12 alert alert-danger"><i className="fas fa-arrow-alt-circle-up" /> {error}</div> }
                    </div>
                </div>
            </div>
        )
    }
}
export default FormInputCheckbox