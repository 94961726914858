import React, {Component} from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row
} from "reactstrap";

import {connect} from "react-redux";
import { getContent } from "../client/actions/apiActions";
import Loader from "./layout/Loader";

class ContentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentPage: '',
      viewMode: 'loading',
      pageUrl: '',
      pageTitle: '',
      pageHTML: ''
    };
  }
  componentDidMount() {
    const {  languageCode } = this.props.auth.user;
    this.props.getContent(window.location.pathname, languageCode);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props)
    {
      console.log(this.props.data);
      this.setState({
        pageTitle: this.props.data.pageTitle,
        pageHTML: this.props.data.pageHTML,
        viewMode: 'data'
      });
    }
  }

  render() {
    const {viewMode, pageTitle, pageHTML} = this.state;
    if (viewMode === 'loading') {
      return (<Loader />);
    } else {
      return (<Container fluid className="p-0">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h1">{pageTitle}</CardTitle>
                <div dangerouslySetInnerHTML={{__html: pageHTML}} />
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </Container>);
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, {getContent} )(ContentPage);