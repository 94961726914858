import React, {Component} from 'react';
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";

import store from "./client/store";
import Routes from "./client/routes/Routes";
import { loadUser } from "./client/actions/auth";

class App extends Component {

    componentDidMount() {
        store.dispatch(loadUser());
    }

    render() {
        return (
            <Provider store={store}>
                <Routes />
                <ReduxToastr
                    timeOut={5000}
                    newestOnTop={true}
                    position="top-right"
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                    progressBar
                    closeOnToastrClick
                />
            </Provider>
        );
    }
}

export default App;
