import React, {Component} from "react";
import {
  Col,
  Container,
  Row
} from "reactstrap";

import { languageText } from "../config";
import DataOverview from "./DataOverview";
import {connect} from "react-redux";
import { getProfileInfo } from "../client/actions/apiActions";
import Loader from "./layout/Loader";
import ProfileLinks from "./ProfileLinks";
import {Redirect} from "react-router-dom";

const tableName = "usersPassword";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: 'data',
      fieldData: [
        {
          fieldName: "password",
          fieldType: "password",
          colSize: 2,
          required: true
        },
        {
          fieldName: "passwordConfirm",
          fieldType: "password",
          colSize: 2,
          required: true
        }
      ],
      fieldDataResults: {password: '',passwordConfirm: '', viewMode: 'data'},
      logout: false
    };
  }

  componentDidMount() { }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== this.props)
    {
      console.log("Password Props", this.props.data.status);
      if (this.props.data.status === 1) {
        this.setState({logout: true})
      }
    }
  }

  render() {
    const {fieldDataResults, viewMode, fieldData, fieldDataDefault} = this.state;
    if (this.state.logout === true) {
      return (<Redirect to="/auth/sign-out" />);
    } else if (viewMode === 'loading') {
      return (<Loader />);
    } else {
      const { languageCode } = this.props.auth.user;
      return (<Container fluid className="p-0">
        <Row>
          <Col md="3" xl="2">
            <ProfileLinks linkActive="/profile-password" />
          </Col>
          <Col md="9" xl="10">
            <DataOverview
                singleEdit={true}
                tableName={tableName}
                fieldData={fieldData}
                fieldDataResults={fieldDataResults}
                settings={{ 'showClone': false }}
                languageTable={ tableName }
                pageTitle={languageText[languageCode].components[tableName].pageTitle}
                listTitle={languageText[languageCode].components[tableName].listTitle}
                listSubTitle={languageText[languageCode].components[tableName].listSubTitle}
                addTitle={languageText[languageCode].components[tableName].addTitle}
                addSubTitle={languageText[languageCode].components[tableName].addSubTitle}
                editTitle={languageText[languageCode].components[tableName].editTitle}
                editSubTitle={languageText[languageCode].components[tableName].editSubTitle}
                fieldDataDefault={fieldDataDefault}
            />
          </Col>
        </Row>
      </Container>);
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, {getProfileInfo} )(Profile);