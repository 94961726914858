import {
  Settings as SettingsIcon,
  Users as TeamMembersIcon,
  Sliders as SlidersIcon,
  Users as UsersIcon
} from "react-feather";

// Auth
import SignIn from "../../pages/auth/SignIn";
import SignUp from "../../pages/auth/SignUp";
import SignOut from "../../pages/auth/SignOut";
import ResetPassword from "../../pages/auth/ResetPassword";
import Page404 from "../../pages/auth/Page404";
import Page500 from "../../pages/auth/Page500";

// Notifications
import Notifications from "../../components/ProfileNotifications";

// COMING HOME Pages
import TeamMembers from "../../pages/Users";
import EmailTemplates from "../../pages/EmailTemplates";

// default pages
import Profile from "../../components/Profile";
import MetaData from "../../pages/MetaData";
import ProfilePassword from "../../components/ProfilePassword";
import SuperAdminPassword from "../../components/SuperAdminPassword";
import ProfileFiles from "../../components/ProfileFiles";
import Content from "../../pages/Content";
import ContentPage from "../../components/ContentPage";
import Clients from "../../pages/Clients";
import Dashboard from "../../pages/Dashboard";
import Users from "../../pages/Users";
import BugTracker from "../../pages/BugTracker";
import ListingPrice from "../../pages/ListingPrice";
import Events from "../../pages/Events";
import Incentives from "../../pages/Incentives";
import ListingUsers from "../../pages/ListingUsers";
import Subscriptions from "../../pages/Subscriptions";
import EmailConfig from "../../pages/EmailConfig";


// Routes
const landingRoutes = {
  path: "/confirm-invitation",
  name: "Confirm Invitation",
  component: SignUp,
  isPrivate: false,
  children: null
};

const dashboardRoutes = {
  path: "/",
  name: "Dashboard",
  langName: "dashboard",
  component: Dashboard,
  icon: SlidersIcon,
  containsHome: true,
  isPrivate: true,
  children: null
};

const clientsRoutes = {
  path: "/clients",
  name: "Listings",
  icon: SlidersIcon,
  isPrivate: true,
  children: [
    {
      isPrivate: true,
      path: "/clients/listings",
      name: "Listings",
      component: Clients
    },
    {
      isPrivate: true,
      path: "/clients/listing-users",
      name: "Users",
      component: ListingUsers
    },
    {
      isPrivate: true,
      path: "/clients/subscriptions",
      name: "Subscriptions",
      component: Subscriptions
    },
    {
      isPrivate: true,
      path: "/clients/events",
      name: "Events",
      langName: "events",
      component: Events
    },
    {
      isPrivate: true,
      path: "/clients/incentives",
      name: "Incentives",
      langName: "incentives",
      component: Incentives
    },
  ],
  component: Clients
};


const teamMembersRoutes = {
  path: "/organizations/users",
  name: "Team Members",
  langName: "users",
  icon: TeamMembersIcon,
  children: null,
  isPrivate: true,
  component: Users
}

// SETTINGS LINK
const settingsRoutes = {
  path: "/settings",
  name: "Settings",
  icon: SettingsIcon,
  children: [
    {
      isPrivate: true,
      path: "/settings/email-templates",
      name: "Email Templates",
      component: EmailTemplates
    },
    {
      isPrivate: true,
      path: "/settings/content",
      name: "Content",
      component: Content
    },
    {
      isPrivate: true,
      path: "/settings/meta-data",
      name: "Meta Data",
      component: MetaData
    },
    {
      isPrivate: true,
      path: "/settings/bug-tracker",
      name: "Bug Tracker",
      component: BugTracker
    },
    {
      isPrivate: true,
      path: "/settings/listing-price",
      name: "Listing Price",
      component: ListingPrice
    },
    {
      isPrivate: true,
      path: "/settings/email-config",
      name: "Email Config",
      component: EmailConfig
    },
  ]
};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "Special",
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      isPrivate: false,
      component: SignIn
    },
    {
      path: "/auth/sign-out",
      name: "Sign Out",
      isPrivate: false,
      component: SignOut
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      isPrivate: false,
      component: ResetPassword
    },
    {
      path: "/admin/login",
      name: "Sign In",
      isPrivate: false,
      component: SignIn
    }
  ]
};

// ROUTES NOT VISIBLE IN THE SIDEBAR
const privateRoutes = {
  path: "/privacy",
  name: "Privacy",
  children: [

    {
      path: "/organizations/users/:organizationId",
      name: "Organization Users",
      isPrivate: true,
      component: TeamMembers
    },
    {
      path: "/privacy",
      name: "Privacy",
      isPrivate: true,
      component: ContentPage
    },
    {
      path: "/support-center",
      name: "Support",
      isPrivate: true,
      component: ContentPage
    },
    {
      path: "/help-center",
      name: "Help Center",
      isPrivate: true,
      component: ContentPage
    },
    {
      path: "/terms-of-service",
      name: "Terms of Service",
      isPrivate: true,
      component: ContentPage
    },
    {
      path: "/profile",
      name: "Profile",
      isPrivate: true,
      component: Profile
    },
    {
      path: "/profile-password",
      name: "Password",
      isPrivate: true,
      component: ProfilePassword
    },
    {
      path: "/super-admin-password",
      name: "Edit Password",
      isPrivate: true,
      component: SuperAdminPassword
    },
    {
      path: "/profile-files",
      name: "Files",
      isPrivate: true,
      component: ProfileFiles
    },
    {
      path: "/profile-notifications",
      name: "Notifications",
      isPrivate: true,
      component: Notifications
    },
    {
      path: "/notifications",
      name: "Notifications",
      isPrivate: true,
      component: Notifications
    },
    {
      path: "/404",
      name: "404 Page",
      isPrivate: false,
      component: Page404
    },
    {
      path: "/500",
      name: "500 Page",
      isPrivate: false,
      component: Page500
    },
    {
      path: "/auth/sign-out",
      name: "sign out",
      isPrivate: true,
      component: SignOut
    }
  ]
};

// ADD  ROUTES - if not added here can not be visited
// Dashboard specific routes
export const dashboard = [
  dashboardRoutes,
  clientsRoutes,
  teamMembersRoutes,
  settingsRoutes,
  privateRoutes,
];

// Landing specific routes
export const landing = [landingRoutes];

// Auth specific routes
export const page = [authRoutes];

// ROUTES TO SHOW IN NAVBAR
export default [
  dashboardRoutes,
  clientsRoutes,
  teamMembersRoutes,
  settingsRoutes,
];
